let _isCypress: any;

export const isCypress = () => {
  if (_isCypress !== undefined) return _isCypress;

  try {
    // @ts-ignore parent.Cypress exists in cypress runs
    _isCypress = !!window.parent.Cypress;
  } catch (err) {
    // accessing window.parent may be blocked by browsers by default.
    // in that case, it's not a cypress test run (that browser safeguard is disabled in cy)
    _isCypress = false;
  }

  return _isCypress;
};
